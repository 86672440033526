import {
  ensureArray,
  arrayNotEmpty,
  stringNotEmpty,
  objectNotEmpty,
  ensureObject,
  unsnakecase,
  titleCase,
} from '@agnostack/lib-core'
import { cloneObject } from '@agnostack/lib-utils-js'

export const getParentCategory = ({ edge, edges, paths }) => {
  const {
    node: {
      frontmatter: {
        template,
        category,
        parent,
      } = {},
    } = {},
  } = ensureObject(edge)

  let parentCategory = category
  if (!stringNotEmpty(category) && stringNotEmpty(parent)) {
    if (arrayNotEmpty(edges)) {
      const {
        node: {
          frontmatter: {
            category: edgeCategory,
          } = {},
        } = {},
      } = ensureObject(
        edges.find(({
          node: {
            frontmatter: {
              title: edgeTitle,
              template: edgeTemplate,
            } = {},
          } = {},
        }) => (edgeTitle === parent && edgeTemplate === template))
      )

      if (stringNotEmpty(edgeCategory)) {
        parentCategory = edgeCategory
      }
    } else if (objectNotEmpty(paths)) {
      const pathCategory = ensureArray(Object.entries(paths).find(([, nodes]) => (
        ensureArray(nodes).find(({
          frontmatter: {
            title: pathTitle,
            template: pathTemplate,
          } = {},
        }) => (pathTitle === parent && pathTemplate === template))
      )))[0]

      if (stringNotEmpty(pathCategory)) {
        parentCategory = pathCategory
      }
    }
  }

  return parentCategory
}

export const getTitle = (edge) => {
  const {
    context: {
      tag,
      tagData: {
        title: tagTitle,
        modifier,
        modifier: {
          text: modifierText = '',
        } = {},
        prepend: {
          text: prependText,
        } = {},
      } = {},
      node: {
        frontmatter: {
          title,
          version,
          subtitle,
          template,
          category,
          siteMetadata: {
            title: siteTitle,
          } = {},
        } = {},
      } = {},
    } = {},
  } = ensureObject(edge)

  let displayTitle
  switch (template) {
    case 'faqs':
    case 'press_releases':
      displayTitle = `${
        title ? `${title}${subtitle ? ` ${subtitle}` : ''}` : titleCase(unsnakecase(template))
      }`
      break
    case 'release_notes':
      displayTitle = `${titleCase(unsnakecase(template))}${
        version ? `: ${version}` : ''
      }`
      break
    case 'tag':
      displayTitle = tagTitle || `${titleCase(tag)}${modifier ? ` + ${prependText || modifierText}` : ''}`
      break
    default:
      displayTitle = title || category || siteTitle
      break
  }

  return displayTitle
}

const getNavigationItem = (text, item) => {
  const { frontmatter, path: _path } = ensureObject(item)
  const { title } = ensureObject(frontmatter)
  return {
    ...frontmatter,
    text: text || title,
    path: _path,
  }
}

export const getNavigationLinks = ({
  path: _path,
  subPaths: _subPaths,
  maxItems = 2,
  labels = ['Previous', 'Next'],
}) => {
  const subPaths = cloneObject(ensureArray(_subPaths))

  const currentIndex = subPaths.findIndex(({ path: subPath } = {}) => (
    subPath === _path
  ))

  const halfCount = maxItems / 2

  let firstIndex = Math.min(subPaths.length - maxItems, Math.max(0, currentIndex - Math.floor(halfCount)))
  let lastIndex = Math.min(subPaths.length, firstIndex + maxItems)

  if (currentIndex <= 0) {
    subPaths[0] = undefined
    lastIndex += 1
  } else if (currentIndex >= subPaths.length - 1) {
    subPaths[subPaths.length - 1] = undefined
    firstIndex -= 1
  }

  const navigationItems = subPaths.slice(firstIndex, lastIndex)

  return navigationItems.map((navigationItem, index) => (
    getNavigationItem(labels[index] || index, navigationItem)
  ))
}
