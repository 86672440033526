import {
  flags,
  ensureObject,
  objectEmpty,
  PLAN_FREE,
  PLAN_STARTER,
  PLAN_PROFESSIONAL,
  PLAN_PREMIUM,
  PLAN_ENTERPRISE,
  PRODUCT_NAMES,
} from '@agnostack/lib-core'

import { arrayIncludesAny } from './display'
import { LISTINGS } from './listings'

const findProvidersByType = (providerSets, providersType) => (
  Object.values(ensureObject(providerSets)).reduce((
    _providerTypes,
    {
      [providersType]: {
        type: providerType,
      } = {},
    }
  ) => ([
    ..._providerTypes,
    providerType
  ]), [])
)

// NOTE: PLAN_FREE is blocklisted for everything except where explicitly
// removed in providersProductsBlocklist for Magento1Provider M1 below.
export const defaultProductsBlocklist = [
  PRODUCT_NAMES[PLAN_FREE]
]

export const productsDependencyBlocklist = {
  commerceProvider: {
    Magento1Provider: ({
      commerceProvider: {
        config: {
          api_version,
        } = {},
      } = {},
    } = {}) => (
      (api_version !== 'v2') ? [
        PRODUCT_NAMES[PLAN_PROFESSIONAL],
        PRODUCT_NAMES[PLAN_PREMIUM], // NOTE: These are explicitly disabled as we do not want any write operations for M1
        PRODUCT_NAMES[PLAN_ENTERPRISE] // NOTE: These are explicitly disabled as we do not want any write operations for M1
      ] : [
        PRODUCT_NAMES[PLAN_PREMIUM], // NOTE: These are explicitly disabled as we do not want any write operations for M1
        PRODUCT_NAMES[PLAN_ENTERPRISE] // NOTE: These are explicitly disabled as we do not want any write operations for M1
      ]
    ),
    Magento2Provider: ({
      paymentProvider,
    } = {}) => (
      // TODO/HMMMMM: not sure why no fallback?
      // objectEmpty(paymentProvider) && [
      //   ...defaultProductsBlocklist,
      //   PRODUCT_NAMES[PLAN_PREMIUM],
      //   PRODUCT_NAMES[PLAN_ENTERPRISE]
      // ]
      objectEmpty(paymentProvider) ? [
        ...defaultProductsBlocklist,
        PRODUCT_NAMES[PLAN_PREMIUM],
        PRODUCT_NAMES[PLAN_ENTERPRISE]
      ] : defaultProductsBlocklist // HMMM: not sure if this should fall back to defaultProductsBlocklist or []
    ),
    MoltinProvider: () => (
      // TODO!!!!: remove this to have providers.js fall back to defaultProductsBlocklist if none found for provider
       defaultProductsBlocklist
    ),
    BigCommerceProvider: () => (
       defaultProductsBlocklist
    ),
    SalesforceB2CProvider: () => (
      // TODO!!!!: remove this to have providers.js fall back to defaultProductsBlocklist if none found for provider
       defaultProductsBlocklist
    ),
    ShopifyProvider: () => (
      // TODO!!!!: remove this to have providers.js fall back to defaultProductsBlocklist if none found for provider
       defaultProductsBlocklist
    ),
    AmazonProvider: () => (
      // TODO!!!!: remove this to have providers.js fall back to defaultProductsBlocklist if none found for provider
       defaultProductsBlocklist
    ),
    CommerceToolsProvider: () => (
      defaultProductsBlocklist
    ),
    WooCommerceProvider: () => ([
      ...defaultProductsBlocklist,
      PRODUCT_NAMES[PLAN_ENTERPRISE]
    ]),
    StripeProvider: () => ([
      ...defaultProductsBlocklist,
      PRODUCT_NAMES[PLAN_ENTERPRISE]
    ]),
  },
  paymentProvider: {
    SuggestedProvider: () => ([
      ...defaultProductsBlocklist,
      PRODUCT_NAMES[PLAN_PREMIUM],
      PRODUCT_NAMES[PLAN_ENTERPRISE]
    ]),
  },
  shippingProvider: {
    SuggestedProvider: () => ([
      ...defaultProductsBlocklist,
      PRODUCT_NAMES[PLAN_ENTERPRISE]
    ]),
  },
}

// NOTE: optional localized 'description' for each key in locale files
export const featureSetsConfig = {
  macros_package: {
    title: 'Macros Package',
    price: 400, // TODO: support monthly/non per-agent pricing???
    flags: [
      flags.FLAG_MACROS_SNIPPETS,
      flags.FLAG_MACROS_ADVANCED
    ],
  },
  advanced_refunds: {
    title: 'Advanced Refunds',
    price: 300, // TODO: support monthly/non per-agent pricing???
    flags: [
      flags.FLAG_REFUND_PARTIAL,
      flags.FLAG_REFUND_CUSTOM
    ],
    conditional: ({
      providerSets,
      product: {
        name: selectedTier,
      } = {},
    }) => (
      ![ // TODO: explore passing 'configuredEnabledFlags' into conditionals and doing getMatched on 'customer_details_advanced' here
        ...defaultProductsBlocklist,
        PRODUCT_NAMES[PLAN_STARTER],
        PRODUCT_NAMES[PLAN_PROFESSIONAL]
      ].includes(selectedTier)
      && arrayIncludesAny([
        'BigCommerceProvider',
        'CommerceToolsProvider',
        'Magento2Provider',
        'ShopifyProvider'
      ], findProvidersByType(providerSets, 'commerceProvider'))
    ),
  },
  refunds_package: {
    title: 'Refunds Package',
    price: 600, // TODO: support monthly/non per-agent pricing???
    flags: [
      flags.FLAG_REFUND_REASON,
      flags.FLAG_REFUND_ORDER,
      flags.FLAG_REFUND_PARTIAL,
      flags.FLAG_REFUND_CUSTOM,
      flags.FLAG_REFUND_ITEM
    ],
    conditional: ({
      providerSets,
      product: {
        name: selectedTier,
      } = {},
    }) => (
      [ // TODO: explore passing 'configuredEnabledFlags' into conditionals and doing getMatched on 'customer_details_advanced' here
        PRODUCT_NAMES[PLAN_STARTER],
        PRODUCT_NAMES[PLAN_PROFESSIONAL]
      ].includes(selectedTier)
      && arrayIncludesAny([
        'BigCommerceProvider',
        'CommerceToolsProvider',
        'Magento2Provider',
        'ShopifyProvider'
      ], findProvidersByType(providerSets, 'commerceProvider'))
    ),
  },
  metadata_package: {
    title: 'Metadata Package',
    price: 400, // TODO: support monthly/non per-agent pricing???
    flags: [flags.FLAG_CUSTOMER_METADATA, flags.FLAG_ORDER_METADATA],
    conditional: ({
      providerSets,
      product: {
        name: selectedTier,
      } = {},
    }) => (
      // TODO: explore passing 'configuredEnabledFlags' into conditionals and doing getMatched on 'customer_details_advanced' here
      (selectedTier === PRODUCT_NAMES[PLAN_PREMIUM]) &&
      arrayIncludesAny([
        'BigCommerceProvider',
        'CommerceToolsProvider',
        // 'Magento2Provider',
        'ShopifyProvider'
      ], findProvidersByType(providerSets, 'commerceProvider'))
    ),
  },
  reminders_package: {
    title: 'Reminders Package',
    price: 300, // TODO: support monthly/non per-agent pricing???
    flags: [
      flags.FLAG_REMINDERS,
      flags.FLAG_REMINDERS_ORDER
    ],
  },
  watchlist_package: {
    title: 'Watchlist Package',
    price: 300, // TODO: support monthly/non per-agent pricing???
    // flags: [flags.FLAG_ORDER_SAVE, flags.FLAG_CUSTOMER_SAVE, flags.FLAG_TICKET_SAVE], // TODO reanble once ticket panel is live
    flags: [flags.FLAG_ORDER_SAVE, flags.FLAG_CUSTOMER_SAVE],
  },
  collab_package: {
    title: 'Collab Package',
    price: 400, // TODO: support monthly/non per-agent pricing???
    flags: [
      flags.FLAG_CART_SHARE,
      flags.FLAG_MACROS_CATALOG,
      flags.FLAG_PAYMENT_BILLING,
      flags.FLAG_PAYMENT_SHIPPING,
      flags.FLAG_LOAD_PRODUCTS,
      flags.FLAG_LOAD_PRODUCTS_PAGING
    ],
    conditional: ({
      providerSets,
      product: {
        name: selectedTier,
      } = {},
    }) => (
      // TODO: explore passing 'configuredEnabledFlags' into conditionals and doing getMatched on 'customer_details_advanced' here
      (selectedTier !== PRODUCT_NAMES[PLAN_STARTER]) &&
      (
        arrayIncludesAny([
          'SuggestedProvider' // NOTE: this is presently only available for EP/Moltin
        ], findProvidersByType(providerSets, 'paymentProvider')) ||
        arrayIncludesAny([
          'CommerceToolsProvider'
        ], findProvidersByType(providerSets, 'commerceProvider'))
      )
    ),
  },
  multi_config: {
    title: 'Multi-Configuration',
    price: 400, // TODO: support monthly/non per-agent pricing???
    flags: [flags.FLAG_CONFIG_MULTIPLE],
    // TODO: send in app_data to conditional - and change this to show if app_data.auth_type !== 'single'
    conditional: ({ listing }) => (listing === LISTINGS.AGNOSTACK),
  },
}

// NOTE: for any new set, need to add descriptions to translation (labels.permission-sets.{})
export const permissionSetsConfig = {
  refunds: {
    title: 'Refund Permissions',
    flags: [
      flags.FLAG_REFUND_REASON,
      flags.FLAG_REFUND_ORDER,
      flags.FLAG_REFUND_PARTIAL,
      flags.FLAG_REFUND_CUSTOM,
      flags.FLAG_REFUND_ITEM
    ],
  },
  products_load: {
    title: 'Preload Catalog Products',
    flags: [
      flags.FLAG_LOAD_PRODUCTS
    ],
  },
  payment: {
    title: 'Payment Capture',
    flags: [
      flags.FLAG_PAYMENT_CAPTURE
    ],
  },
  order: {
    title: 'Order Capture',
    flags: [
      flags.FLAG_ORDER_CREATE,
      flags.FLAG_ORDER_DUPLICATE
    ],
  },
  orders: {
    title: 'Order Permissions',
    flags: [
      flags.FLAG_ORDER_MODIFY,
      flags.FLAG_ORDER_ADD_ITEMS,
      flags.FLAG_ORDER_MODIFY_ITEMS,
      flags.FLAG_ORDER_MODIFY_ITEMS_PRICE,
      flags.FLAG_ORDER_MODIFY_ADDRESS_ADVANCED,
      flags.FLAG_ORDER_MODIFY_ADDRESS
    ],
  },
  order_details: {
    title: 'Advanced Order Details',
    flags: [
      flags.FLAG_ORDER_INSIGHTS,
      flags.FLAG_ORDER_METADATA
    ],
  },
  customer_details: {
    title: 'Advanced Customer Details',
    flags: [
      flags.FLAG_CUSTOMER_DETAILS_ADVANCED,
      // TODO: ensure we add FLAG_CUSTOMER_INSIGHTS here (once it exists)
      flags.FLAG_CUSTOMER_METADATA
    ],
  },
  cart: {
    title: 'Modify Cart Items',
    flags: [
      flags.FLAG_CART_MODIFY_ITEMS_PRICE
      // flags.FLAG_CART_PROMOTION
    ],
  },
  status: {
    title: 'Modify Status',
    flags: [
      flags.FLAG_ORDER_MODIFY_STATUS
    ],
  },
  macros: {
    title: 'Macro Permissions',
    flags: [
      flags.FLAG_MACROS_SNIPPETS,
      flags.FLAG_MACROS_ADVANCED
    ],
  },
  tags: {
    title: 'Manage Tags',
    flags: [
      flags.FLAG_CUSTOMER_MODIFY_TAGS,
      flags.FLAG_ORDER_MODIFY_TAGS
    ],
  },
  cart_attribution: {
    title: 'Cart Attribution',
    flags: [
      flags.FLAG_ATTRIBUTION_CART
    ],
  },
  customer_credits: {
    title: 'Customer Credits',
    flags: [
      flags.FLAG_CUSTOMER_MODIFY_STORE_CREDIT,
      flags.FLAG_CUSTOMER_MODIFY_GIFT_CARDS
    ],
  },
}
