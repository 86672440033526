import { useState, useEffect, useCallback, useRef } from 'react'

import { deepEqual } from '@agnostack/lib-utils-js'

export const useAsyncMemo = (promisable, deps, initial, enhanced) => {
  const memoizedPromisable = useCallback(promisable, deps)
  const [val, setVal] = useState(initial)
  const cancelRef = useRef(false)

  useEffect(() => {
    cancelRef.current = false

    const promise = memoizedPromisable?.()
    if (promise === undefined) {
      return
    }

    promise.then((_val) => {
      if (!cancelRef.current && (!enhanced || !deepEqual(_val, initial))) {
        setVal(_val)
      }
    })
    .catch((error) => {
      if (!cancelRef.current) {
        console.error('Error in useAsyncMemo', error)
      }
    })

    // TODO: confirm this is working/bring back???
    // eslint-disable-next-line consistent-return
    // return () => {
    //   if (enhanced) {
    //     console.log(`!!!!!!!!! CANCELLING`)
    //     cancelRef.current = true
    //   }
    // }
  }, [memoizedPromisable])

  return val
}

export const useAsyncMemoEnhanced = (promisable, deps, initial) => (
  useAsyncMemo(promisable, deps, initial, true)
)
