import { useRef, useEffect } from 'react'

export const usePrevious = (value, initialValue, ensureValue = false) => {
  const ref = useRef(initialValue)

  useEffect(() => {
    if (!ensureValue || (value !== initialValue)) {
      ref.current = value
    }
  }, [value])

  return ref?.current
}

// NOTE: this only stores things that have a value (ie. if something is toggled 'foo'>undefined>'bar', it will remember 'foo' when bar is set)
export const usePreviousValue = (value, initialValue) => (
  usePrevious(value, initialValue, true)
)
