import { useRef, useState, useEffect } from 'react'

export const useInterval = (callback, _frequencyDuration) => {
  const callbackRef = useRef()
  const tickCountRef = useRef(0)
  const [frequency, setFrequency] = useState()

  const handleStart = (updatedFrequency = frequency) => {
    setFrequency(updatedFrequency)
  }

  const handleStop = () => {
    setFrequency()
    tickCountRef.current = 0
  }

  const tick = () => {
    tickCountRef.current += 1
    callbackRef.current?.(tickCountRef.current)
  }

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (_frequencyDuration != undefined) {
      handleStart(_frequencyDuration)
    }
  }, [_frequencyDuration])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (frequency != undefined) {
      const intervalId = setInterval(tick, frequency)
      return () => {
        try {
          handleStop()
          clearInterval(intervalId)
        // eslint-disable-next-line no-empty
        } catch {}
      }
    }

    return undefined
  }, [frequency])

  return {
    frequency,
    actions: {
      start: handleStart,
      stop: handleStop,
    },
  }
}
