import styled from 'styled-components'
import Markdown from 'markdown-to-jsx'

import { Tag } from '@zendeskgarden/react-tags'
import { Well } from '@zendeskgarden/react-notifications'

import { Anchor } from '../../styles/core'

export const MARKDOWN_STYLES = (theme) => `
  ol {
    list-style: decimal-leading-zero;
  }
  ul {
    list-style: square;
    padding-inline-start: ${theme?.lineHeights?.sm};
  }
  ul.padded li {
    padding-bottom: ${theme?.space?.xs};
  }
  img.avatar {
    padding: ${theme?.space?.xxs};
    border-radius: 50%;
    border: 2px solid ${theme?.palette?.[theme?.colors?.neutralHue]?.[200]};
  }
  img.third {
    width: 33%;
    max-width: 330px;
  }
  img.third {
    width: 33%;
    max-width: 330px;
  }
  img.narrow {
    width: 60%;
    max-width: 600px;
  }
  img.wide {
    width: 80%;
    max-width: 700px;
  }
  img.mobile {
    @media (max-width: ${theme?.breakpoints?.md}) {
      width: 90%;
    }
  }
  img.mobile.logo {
    @media (max-width: ${theme?.breakpoints?.md}) {
      max-width: 200px;
    }
  }
  img.greyscale {
    filter: grayscale(.9);
  }
  img.shadow {
    box-shadow: 0 15px 50px ${theme?.formats?.default?.color};
  }
  .max {
    max-width: 100%;
  }
  .border {
    max-width: 100%;
    border: ${theme?.borders?.sm} ${theme?.colors?.tertiary};
  }
  .link-group { 
    display: inline-table;
    text-align: center;
  }
  .logo {
    padding: 12px;
  }
  .image-group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;

    @media (max-width: ${theme?.breakpoints?.md}) {
      justify-content: center;
      gap: 1rem;
    }
  }
  .image-group.items-center {
    align-items: center;
  }
  .columns {
    display: flex;
    @media (max-width: ${theme.breakpoints.sm}) {
      display: block;
    }
  }
  .columns.center {
    justify-content: center;
  }
  .columns div { 
    flex-grow: 1;
    flex-basis: 0;
  }
  .columns .quarter { 
    flex-grow: .3;
    flex-basis: 0;
  }
  .columns .third { 
    flex-grow: .5;
    flex-basis: 0;
  }
  .columns .right {
    padding-left: 2vw;
    padding-right: 1vw;
  }
  .columns .left {
    padding-right: 2vw;
  }
  .columns .center {
    text-align: center;
  }
  .callout {
    padding: ${theme?.space?.lg} ${theme?.space?.xl};
    background-color: ${theme?.palette?.[theme?.colors?.neutralHue]?.[100]};
  }
  .sidebar {
    max-width: 330px!important;
  }
  .announcement {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    gap: ${theme?.space?.xs};
  }
`

export const StyledMDX = styled.div`
  ${({ theme }) => MARKDOWN_STYLES(theme)}
`

export const StyledMarkdown = styled(Markdown)`
  ${({ theme }) => MARKDOWN_STYLES(theme)}
`

export const BackgroundWell = styled(Well)`
  ${({ pad = true, src, theme }) => `
    ${pad ? `
      margin: ${theme.space.xs} 0;
    ` : ''}
    ${src ? `
      background: url(${src}) no-repeat left top;
      background-size: 100%;
    ` : ''}
  `}
`

export const SelectableAnchor = styled(Anchor)`
  user-select: text;
`

export const Announcement = styled.div`
  padding-top: 1vh;
  font-style: italic;
  ${({ onClick }) => onClick && `
    cursor: pointer;
  `};
  ${({ theme, format }) => format && `
    color: ${theme.formats[format].color};
  `};
  span:last-child {
    ${({ theme, format, hasDate }) => (format && hasDate) && `
      color: ${theme.formats[theme.formats[format].compliment].accent};
    `};
  }
`

export const PillButton = styled(Tag).attrs(() => ({
  isPill: true,
  size: 'large',
  role: 'button',
}))`
  cursor: pointer!important;
  height: unset;
  justify-content: center;
  margin-bottom: 3px;
  transition: background .4s ease, filter .15s ease, transform .2s ease, box-shadow .2s ease;
  ${({ theme }) => `
    box-shadow: 0 2px 2px 0 ${theme.colors.shadow1}, 2px 2px 6px 0 ${theme.colors.shadow2}!important;
  `}

  & > * {
    overflow: unset;
    width: unset;
    flex-grow: 1;
  }

  &:hover {
    filter: saturate(40%) brightness(1.2);
    transform: translate(0px, .5px);
    ${({ theme }) => `
      box-shadow: 0 1px 1px 0 ${theme.colors.shadow1}, 1px 1px 6px 0 ${theme.colors.shadow2}!important;
    `}
  }
`
